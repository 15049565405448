import {BaseModel} from 'app/main/shared/models/base.model';

export class ObjetivoLicenciamentoAmbiental extends BaseModel {
    constructor(
        public id?: number,
        public nome?: string,
        public descricao?: string,
        public situacao?: boolean,
        public cadastro?: Date,
        public atualizacao?: Date,
        public idTipoProcesso?: number
    ) {
        super();
    }

    static fromJson(json: any): ObjetivoLicenciamentoAmbiental {
        if (json === undefined || json === null) {
            return null;
        }
        return new ObjetivoLicenciamentoAmbiental(
            json.id,
            json.nome,
            json.descricao,
            json.situacao,
            json.cadastro,
            json.atualizacao,
            json.idTipoProcesso
        );
    }

    static fromJsons(json: any): ObjetivoLicenciamentoAmbiental[] {
        const objetivos: ObjetivoLicenciamentoAmbiental[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(objetivo => objetivos.push(this.fromJson(objetivo)));
            return objetivos;
        }
    }

}
