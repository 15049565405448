import {Injectable, Injector} from '@angular/core';
import {ListResolver} from "../../shared/resolvers/list-resolver";
import {DetailResolver} from "../../shared/resolvers/detail-resolver";
import {RoutingStateService} from "../../core/services/routing-state.service";
import {BaseService} from "../../shared/services/base.service";
import {MatPaginator} from "@angular/material/paginator";
import {Observable} from "rxjs";
import {Page} from "../../shared/models/page.model";
import {HttpParams} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {FormularioLicenciamentoAmbiental} from "./formulario-licenciamento-ambiental.model";

@Injectable({
    providedIn: 'root'
})
@Injectable()
export class FormularioLicenciamentoAmbientalListResolver extends ListResolver<FormularioLicenciamentoAmbiental> {

    constructor(service: FormularioLicenciamentoAmbientalService) {
        super(service);
    }
}

@Injectable()
export class FormularioLicenciamentoAmbientalResolver extends DetailResolver<FormularioLicenciamentoAmbiental> {
    constructor(
        service: FormularioLicenciamentoAmbientalService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class FormularioLicenciamentoAmbientalService extends BaseService<FormularioLicenciamentoAmbiental> {

    constructor(protected injector: Injector) {
        super(
            '/api/formulariolicenciamentoambiental',
            injector,
            FormularioLicenciamentoAmbiental.prototype,
            FormularioLicenciamentoAmbiental.fromJson
        );
    }

    public listar(descricao?: string, page?: MatPaginator): Observable<{} | Page<FormularioLicenciamentoAmbiental>> {
        const url = `${this.urlResource}`;
        let urlParams: HttpParams = new HttpParams();
        urlParams = urlParams.append('descricao', descricao);
        urlParams = urlParams.append('page', '' + page.pageIndex);
        urlParams = urlParams.append('size', '' + page.pageSize);
        return this.http
            .get(url, {params: urlParams})
            .pipe(
                map(response => this.jsonToPage(response)),
                catchError(this.handleError.bind(this))
            );
    }

    public getByTipo(idTipoFormulario: number): Observable<Array<FormularioLicenciamentoAmbiental>> {
        const url = `${this.urlResource}/tipo/${idTipoFormulario}`;
        return this.http
            .get(url)
            .pipe(
                map(response => this.jsonToResources(response)),
                catchError(this.handleError.bind(this))
            );
    }

    getAll(): Observable<FormularioLicenciamentoAmbiental[]> {
        return this.http
            .get(`${this.urlResource}`)
            .pipe(
                map(response => this.jsonToResources(response)),
                catchError(this.handleError.bind(this))
            );
    }

}
