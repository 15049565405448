import { TipoProcesso } from './../models/tipo-processo.model';
import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {TermoReferencia} from "../models/termo-referencia.model";


@Injectable({
    providedIn: 'root'
})
export class LicenciamentoTipoProcessoService {

    urlResource: string = environment.URL_LICENCIAMENTO_API;

    private http: HttpClient;
    
    constructor(private injector: Injector) {
        this.http = injector.get(HttpClient); 
    }

    getAll(): Observable<TipoProcesso[]> {
        return this.http
            .get(`${this.urlResource}/api/tipos-processo`)
            .pipe(
                map(this.jsonDataToList),
                catchError(this.handleError.bind(this))
            );
    }

    getAllAtivosPorPeriodoDeVigencia(): Observable<TipoProcesso[]> {
        const url = `${this.urlResource}/api/tipos-processo/list-all`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonDataToList),
                catchError(this.handleError.bind(this))
            );
    }

    private jsonDataToList(jsonData: any[]): TipoProcesso[] {
        const entries: TipoProcesso[] = [];

        jsonData.forEach(element => {
            const rt = Object.assign(new TipoProcesso(), element);
            entries.push(rt);
        });
        return entries;
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
