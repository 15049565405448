import {Injectable, Injector} from '@angular/core';
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {catchError, map} from 'rxjs/operators';
import {environment} from "../../../../environments/environment";
import {DominioDto} from "../models/dominio-dto.model";

@Injectable({
    providedIn: 'root'
})
export class DominiosService extends BaseService<DominioDto> {

    urlResource: string = environment.URL_DOMINIOS_API;


    constructor(protected injector: Injector) {
        super('/', injector, DominioDto.prototype, DominioDto.fromJson);
    }

    getDominio(tabela: string): Observable<DominioDto[]> {
        const url = this.urlResource + `/api/dominio/tabela`;
        return this.http
            .get(url, {
                params: {tabela: tabela}
            })
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

}
