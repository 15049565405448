import {BaseModel} from 'app/main/shared/models/base.model';

export class FormularioLicenciamentoAmbiental extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: boolean,
        public constante?: string,
        public idTipoFormulario?: number,
        public situacao?: boolean
    ) {
        super();
    }

    static fromJson(json: any): FormularioLicenciamentoAmbiental {
        if (json === undefined || json === null) {
            return null;
        }
        return new FormularioLicenciamentoAmbiental(
            json.id,
            json.descricao,
            json.constante,
            json.idTipoFormulario,
            json.situacao
        );
    }

}
