import {BaseModel} from 'app/main/shared/models/base.model';

export class TipoProcesso extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public tipo?: string,
        public custoDiariaVistoria?: number,
        public custoKmRodadoVistoria?: number,
        public ativo?: boolean,
        public instanciaRequerimento?: string
    ) {
        super();
    }

    public isOutorga() : boolean {
        return this.tipo === 'OUTORGA';
    }

    static fromJson(json: any): TipoProcesso {
        if (json === undefined || json === null) {
            return null;
        }
        return new TipoProcesso(
            json.id,
            json.descricao,
            json.tipo,
            json.custoDiariaVistoria,
            json.custoKmRodadoVistoria,
            json.ativo,
            json.instanciaRequerimento
        );
    }
}
