import {Injectable, Injector} from '@angular/core';
import {BaseService} from "../../shared/services/base.service";
import {ObjetivoLicenciamentoAmbiental} from "./objetivo-licenciamento-ambiental.model";
import {ListResolver} from "../../shared/resolvers/list-resolver";
import {DetailResolver} from "../../shared/resolvers/detail-resolver";
import {RoutingStateService} from "../../core/services/routing-state.service";
import {MatPaginator} from "@angular/material/paginator";
import {Observable} from "rxjs";
import {Page} from "../../shared/models/page.model";
import {HttpParams} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ObjetivoLicenciamentoAmbientalListResolver extends ListResolver<ObjetivoLicenciamentoAmbiental> {

    constructor(service: ObjetivoLicenciamentoAmbientalService) {
        super(service);
    }
}

@Injectable()
export class ObjetivoLicenciamentoAmbientalResolver extends DetailResolver<ObjetivoLicenciamentoAmbiental> {
    constructor(
        service: ObjetivoLicenciamentoAmbientalService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class ObjetivoLicenciamentoAmbientalService extends BaseService<ObjetivoLicenciamentoAmbiental> {

    constructor(protected injector: Injector) {
        super(
            '/api/objetivolicenciamentoambiental',
            injector,
            ObjetivoLicenciamentoAmbiental.prototype,
            ObjetivoLicenciamentoAmbiental.fromJson
        );
    }

    public listar(descricao?: string, page?: MatPaginator): Observable<{} | Page<ObjetivoLicenciamentoAmbiental>> {
        const url = `${this.urlResource}`;
        let urlParams: HttpParams = new HttpParams();
        urlParams = urlParams.append('descricao', descricao);
        urlParams = urlParams.append('page', '' + page.pageIndex);
        urlParams = urlParams.append('size', '' + page.pageSize);
        return this.http
            .get(url, {params: urlParams})
            .pipe(
                map(response => this.jsonToPage(response)),
                catchError(this.handleError.bind(this))
            );
    }

}